import React, { useContext } from 'react';
import { Location, WindowLocation } from '@gatsbyjs/reach-router';

import { FacilityDetailsWithVideo, getUrlPathToClubFacility } from './facilities';
import { WebsiteLink } from '../common/website-link';
import FadedImage from '../common/faded-image';
import VimeoVideo from '../common/vimeo-video';
import imageFade from 'src/helpers/image-fades';
import { isOnClubPage } from 'src/helpers/path-matching-helper';
import { borderRadius, colors } from 'src/styling/constants';
import { SiteContext } from 'src/context/site-context';
import { CmsContent } from 'src/content';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';

const wrapperClassNameForThumbnails = 'pa2 f5 w-12-rem h-8-rem';

type FacilityProps = {
  facilityType: FacilityDetailsWithVideo;
  useSmallThumbnails: boolean;
  gtmTrackingId?: string;
  onClick?: () => void;
  isNav?: boolean;
};

const Facility = (props: FacilityProps) => (
  <Location>{({ location }) => <FacilityContent {...props} location={location} />}</Location>
);

const FacilityImage = ({
  facilityType,
  useSmallThumbnails,
  isNav,
}: {
  facilityType: FacilityDetailsWithVideo;
  useSmallThumbnails: boolean;
  isNav?: boolean;
}) => {
  const cmsData = useContext(CmsContent);
  const navigationContent = cmsData.navigationMenuContent.navigation;
  const { styling, isHarbour, buildType } = useContext(SiteContext);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));
  const textElement = (
    <div className={`${useSmallThumbnails ? 'pa2' : 'pa2 pa4-l'} h-100 flex items-end`}>
      <div
        css={[
          isNewDesign ? styling.fonts.regular : styling.fonts.bold,
          {
            color: 'white',
            textAlign: 'left',
            lineHeight: 1,
            fontSize: isNewDesign && !isNav ? '1.6875rem' : undefined,
          },
        ]}
      >
        {facilityType.displayName}
      </div>
    </div>
  );
  const imageElement = (
    <FadedImage
      className={!isNewDesign && 'rounded'}
      image={navigationContent[facilityType.cmsKey]}
      fadeType={imageFade.fadeDownwards}
      zoomOnHover
      style={
        isNewDesign && {
          borderRadius: borderRadius.image.medium,
        }
      }
    >
      {textElement}
    </FadedImage>
  );

  const facilityVideo = facilityType.videoId;

  return (
    <div
      css={{
        borderLeft: isHarbour && !useSmallThumbnails ? `15px solid ${colors.harbourGold}` : null,
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      {facilityVideo && !useSmallThumbnails ? (
        <>
          {facilityVideo ? (
            <VimeoVideo
              video={{
                videoId: facilityVideo,
                videoPlaceholder: navigationContent[facilityType.cmsKey],
              }}
              loop
              muted
              disableAutopause
            >
              {textElement}
            </VimeoVideo>
          ) : null}
        </>
      ) : (
        imageElement
      )}
    </div>
  );
};

const FacilityContent = ({
  facilityType,
  useSmallThumbnails,
  location,
  gtmTrackingId,
  onClick,
  isNav,
}: FacilityProps & { location: WindowLocation }) => {
  const facilityUrlPath = getUrlPathToClubFacility(facilityType);
  const { siteId, language, buildType } = useContext(SiteContext);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));
  const wrapperClassName = `club-facility pa3 w-50 fl f3 ${isNewDesign ? 'new-design-club-facility' : ''}`;

  return (
    <div className={useSmallThumbnails ? wrapperClassNameForThumbnails : wrapperClassName}>
      {isOnClubPage(location, siteId, facilityUrlPath, language) ? (
        <div
          css={{ height: '100%', overflow: 'hidden', borderRadius: isNewDesign ? 'unset' : '3px' }}
          className="facility"
        >
          <FacilityImage
            facilityType={facilityType}
            useSmallThumbnails={useSmallThumbnails}
            data-gtmid="current-page-facility"
            isNav={isNav}
          />
        </div>
      ) : (
        <div
          css={{
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            borderRadius: isNewDesign ? 'unset' : '3px',
            boxShadow: !isNewDesign && '0px 10px 20px 5px rgba(0, 0, 0, 0.1)',
          }}
          className="facility"
        >
          <WebsiteLink
            to={facilityUrlPath}
            data-gtmid={gtmTrackingId || 'facility-link'}
            handleClick={onClick}
          >
            <FacilityImage
              facilityType={facilityType}
              useSmallThumbnails={useSmallThumbnails}
              isNav={isNav}
            />
          </WebsiteLink>
        </div>
      )}
    </div>
  );
};

export default Facility;
